import { SubscribingComponent } from '@1clickfactory/common/base-component/subscribing.component';
import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    template: '', selector: 'ocf-form-component-base',
    standalone: false
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class FormComponentBase extends SubscribingComponent {
  @Input() required: boolean = false;
  @Input() html!: SafeHtml;
  @Input() label: string = '';
  @Input() hint: string = '';
  @Input() placeholder: string = '';
  @Input() suffix: string = '';

  get invalid(): boolean {
    return !!(this.control && this.control.invalid);
  }

  get dirty(): boolean {
    return !!(this.control && this.control.dirty);
  }

  get touched(): boolean {
    return !!(this.control && this.control.touched);
  }

  get showErrors(): boolean {
    return this.invalid && this.touched;
  }

  get disabled(): boolean {
    return !!(this.control && this.control.disabled);
  }

  get pending(): boolean {
    return !!(this.control && this.control.pending);
  }

  abstract get control(): UntypedFormArray | UntypedFormControl | UntypedFormGroup;
}
