import { Component, HostBinding, Optional, Self } from '@angular/core'
import { ControlContainer, UntypedFormGroup } from '@angular/forms'
import { getContactId, mapContactToFormGroup, mapFormGroupToContact } from '../../models/contacts.maps'
// Short imports would cause circular dependencies, therefore ignore the TS suggestion
// noinspection ES6PreferShortImport
import { FormArrayComponentBase } from '../../../models/formArrayComponentBase'
// noinspection ES6PreferShortImport
import { Contact } from '../../models/contacts.types'

@Component({
    selector: 'ocf-contacts',
    templateUrl: 'ocf-contacts.component.html',
    styleUrls: ['./ocf-contacts.component.scss'],
    standalone: false
})
export class OcfContactsComponent extends FormArrayComponentBase<Contact> {
  showNewContactForm = false

  constructor(@Optional() @Self() formArrayControlContainer: ControlContainer) {
    super(formArrayControlContainer)
  }

  @HostBinding('class.ngInvalid')
  get ngInvalid(): boolean {
    return this.invalid
  }

  @HostBinding('class.ngTouched')
  get ngTouched(): boolean {
    return this.touched
  }

  addContact(): void {
    this.showNewContactForm = true
    this.control.markAsPending()
  }

  removeContact(contact: Contact): void {
    this.remove(contact)
    if (this.showNewContactForm) {
      this.control.markAsPending()
    }
  }

  cancelAddContact(): void {
    this.exitEditMode()
  }

  submitContact(contact: Contact): void {
    this.add(mapContactToFormGroup(contact))
    this.exitEditMode()
  }

  protected getId(entity: Contact): string | number {
    return getContactId(entity)
  }

  protected mapEntityToGroup(entity: Contact): UntypedFormGroup {
    return mapContactToFormGroup(entity)
  }

  protected mapGroupToEntity(group: UntypedFormGroup): Contact {
    return mapFormGroupToContact(group)
  }

  private exitEditMode(): void {
    this.showNewContactForm = false
    this.control.updateValueAndValidity()
  }
}
