import { Component, EventEmitter, Output } from '@angular/core'
import { markAllFormFieldsAsTouched } from '@1clickfactory/forms/helpers/formGroupHelpers'
// noinspection ES6PreferShortImport
import { Contact } from '../../models/contacts.types'
// noinspection ES6PreferShortImport
import { mapContactToFormGroup } from '../../models/contacts.maps'

@Component({
    selector: 'ocf-contacts-form',
    templateUrl: 'ocf-contacts-form.component.html',
    styleUrls: ['ocf-contacts-form.component.scss'],
    standalone: false
})
export class OcfContactsFormComponent {
  @Output() contactSubmit = new EventEmitter<Contact>()
  @Output() cancel = new EventEmitter<void>()
  private _initialValues: Contact = { Name: '', Email: '', Phone: '', Position: '' }
  formGroup = mapContactToFormGroup({ ...this._initialValues })

  onSubmit(): void {
    if (this.formGroup.invalid) {
      markAllFormFieldsAsTouched(this.formGroup)
      return
    }

    this.contactSubmit.emit({ ...this.formGroup.value })
    this.formGroup.reset({ ...this._initialValues })
  }

  onCancel(): void {
    this.formGroup.reset({ ...this._initialValues })
    this.cancel.emit()
  }
}
