import { Component } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormComponentBase } from './formComponentBase';

@Component({
    template: '', selector: 'ocf-form-array-component-base',
    standalone: false
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class FormArrayComponentBase<TEntity> extends FormComponentBase {
  protected constructor(private formArrayControlContainer: ControlContainer) {
    super();
  }

  get items(): TEntity[] {
    return this.formGroups.map(this.mapGroupToEntity);
  }

  get control(): UntypedFormArray {
    return this.formArrayControlContainer && (this.formArrayControlContainer.control as UntypedFormArray);
  }

  get formGroups(): UntypedFormGroup[] {
    return !!this.control ? (this.control.controls as UntypedFormGroup[]) : [];
  }

  add(entity: TEntity | UntypedFormGroup): UntypedFormGroup {
    this.control.markAsTouched();

    if (entity instanceof UntypedFormGroup) {
      return this.addFormGroup(entity);
    }

    return this.addEntity(entity);
  }

  update(_: TEntity | UntypedFormGroup): void {
    throw new Error('update(entity: TEntity | FormGroup) not implemented yet');
  }

  remove(entity: TEntity | UntypedFormGroup): void {
    this.control.markAsTouched();

    if (entity instanceof UntypedFormGroup) {
      this.removeFormGroup(entity);
    } else {
      this.removeEntity(entity);
    }
  }

  findFormGroupBy(predicate: (e: TEntity) => boolean): UntypedFormGroup {
    const wrappedPredicate = (formGroup: UntypedFormGroup) => {
      const entity = this.mapGroupToEntity(formGroup);
      return predicate(entity);
    };

    return this.formGroups.find(wrappedPredicate)!;
  }

  protected getControl(formGroup: UntypedFormGroup, controlName: string & keyof TEntity): AbstractControl {
    return formGroup && formGroup.get(controlName)!;
  }

  protected getControlValue<PName extends string & keyof TEntity>(formGroup: UntypedFormGroup, controlName: PName): TEntity[PName] {
    const formControl = this.getControl(formGroup, controlName);
    return formControl && (formControl.value as TEntity[PName]);
  }

  protected setControlValue<PName extends string & keyof TEntity>(
    formGroup: UntypedFormGroup,
    controlName: PName,
    value: TEntity[PName],
  ): void {
    const formControl = this.getControl(formGroup, controlName);
    formControl.setValue(value);
  }

  /**
   * Used to identify distinct entities in form array
   * @param entity Entity from form array
   */
  protected abstract getId(entity: TEntity): string | number;

  protected abstract mapGroupToEntity(group: UntypedFormGroup): TEntity;

  protected abstract mapEntityToGroup(entity: TEntity): UntypedFormGroup;

  private addEntity(entity: TEntity): UntypedFormGroup {
    const formGroup = this.mapEntityToGroup(entity);
    this.addFormGroup(formGroup);
    return formGroup;
  }

  private addFormGroup(formGroup: UntypedFormGroup): UntypedFormGroup {
    this.control.push(formGroup);
    return formGroup;
  }

  private removeEntity(entity: TEntity): void {
    const group = this.mapEntityToGroup(entity);
    this.removeFormGroup(group);
  }

  private removeFormGroup(formGroup: UntypedFormGroup): void {
    const matchingGroup = this.findFormGroup(formGroup);

    if (matchingGroup instanceof UntypedFormGroup) {
      const index = this.formGroups.indexOf(matchingGroup);
      this.control.removeAt(index);
    } else {
      console.error('Trying to remove non-existing entity');
    }
  }

  private getFormGroupId(entity: UntypedFormGroup): string | number {
    return this.getId(this.mapGroupToEntity(entity));
  }

  private formGroupIdMatcher(id: string | number): (identifier: any) => boolean {
    return formGroup => this.getFormGroupId(formGroup) === id;
  }

  private findFormGroup(formGroup: UntypedFormGroup): UntypedFormGroup {
    const id = this.getFormGroupId(formGroup);
    return this.formGroups.find(this.formGroupIdMatcher(id))!;
  }
}
