import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// ToDo: remove this helper and use native 'markAllAsTouched()' after upgrade to Angular 8+

/**
 * Toggle every field of a form group as 'touched'/'untouched' to activate/deactivate its validation.
 * @param formGroup The form group to traverse recursively.
 * @param markAsTouched 'true' means will be marked as 'touched', else - as 'untouched'.
 */
function validateAllFormFields(formGroup: UntypedFormGroup | UntypedFormArray, markAsTouched: boolean): void {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormArray) {
      markAsTouched ? control.markAsTouched() : control.markAsUntouched();
      control.controls.forEach((c: AbstractControl) => validateAllFormFields(c as UntypedFormGroup, markAsTouched));
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control, markAsTouched);
    } else if (control instanceof UntypedFormControl) {
      markAsTouched ? control.markAsTouched() : control.markAsUntouched();
    }
  });
}

/**
 * Traverse a FormGroup recursively and mark every field as 'touched'.
 */
export function markAllFormFieldsAsTouched(formGroup: UntypedFormGroup): void {
  validateAllFormFields(formGroup, true);
}

/**
 * Traverse a FormGroup recursively and mark every field as 'pristine'.
 */
export function markAllFormFieldsAsPristine(formGroup: UntypedFormGroup): void {
  validateAllFormFields(formGroup, false);
}

/**
 * Flattens two-level form group
 */

export const flattenFormValue = <TResult>({ value }: UntypedFormGroup): TResult => flattenObject<TResult, UntypedFormGroup>(value);
export const flattenRawFormValue = <TResult>(formGroup: UntypedFormGroup): TResult =>
  flattenObject<TResult, UntypedFormGroup>(formGroup.getRawValue());

export const flattenObject = <TResult, TObject extends object>(value: TObject): TResult =>
  Object.keys(value)?.reduce((acc: TResult, cKey: string) => ({ ...acc, ...flattenValue(cKey, value[cKey]) }), {} as TResult);

function flattenValue(key: string, value: unknown): object {
  if (value === null) {
    // Because 'null' is of type 'object'
    return { [key]: value };
  }

  const valueType = typeof value;
  switch (valueType) {
    case 'object':
      return Object.keys(value as object)?.reduce(
        (acc: object, cKey: string) => ({
          ...acc,
          [cKey]: value![cKey],
        }),
        {},
      );
    case 'undefined':
    case 'boolean':
    case 'number':
    case 'string':
    case 'function':
    case 'symbol':
    default:
      return { [key]: value };
  }
}
