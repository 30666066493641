<ng-container [formGroup]="formGroup">
  <cui-input formControlName="Name" i18n-label label="Full name" [required]="true"></cui-input>
  <cui-input formControlName="Email" i18n-label label="E-mail" [required]="true"></cui-input>
  <cui-input formControlName="Position" i18n-label label="Position"></cui-input>
  <cui-input formControlName="Phone" i18n-label label="Phone"></cui-input>

  <div class="buttons">
    <cui-button (clicked)="onCancel()" i18n>Cancel</cui-button>
    <cui-button (clicked)="onSubmit()" color="accent" disableRipple i18n>Add</cui-button>
  </div>
</ng-container>
