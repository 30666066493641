<label>{{ label }}</label>

<ocf-contacts-list
  (contactRemoved)="removeContact($event)"
  *ngIf="!disabled || !!(items && items.length); else notApplicable"
  [contacts]="items"
  [disabled]="disabled"
></ocf-contacts-list>

<ng-container *ngIf="!disabled">
  <ng-container *ngIf="showNewContactForm; then addContactForm; else addContactButton"></ng-container>

  <ocf-contacts-messages [control]="control" [showErrors]="showErrors"></ocf-contacts-messages>
</ng-container>

<ng-template #addContactButton>
  <cui-button (clicked)="addContact()" [disabled]="disabled" i18n>Add contact</cui-button>
  <mat-hint *ngIf="!showErrors">{{ hint }}</mat-hint>
</ng-template>

<ng-template #addContactForm>
  <ocf-contacts-form (cancel)="cancelAddContact()" (contactSubmit)="submitContact($event)"></ocf-contacts-form>
  <mat-hint *ngIf="!showErrors" i18n>Finish adding or remove the contact.</mat-hint>
</ng-template>

<ng-template #notApplicable>
  <p class="not-applicable" i18n>N/A</p>
</ng-template>
