<form [ngClass]="{ hidden: loading }" (ngSubmit)="onSubmit($event)" [formGroup]="form" novalidate>
  <ocf-readonly-notice *ngIf="readonly"></ocf-readonly-notice>

  <ng-content></ng-content>

  <ocf-form-pending-notices [contactsPending]="addingContact" [filesPending]="uploadingFiles"></ocf-form-pending-notices>

  <ocf-failing-validation-notice *ngIf="!addingContact && showValidation"></ocf-failing-validation-notice>

  <ng-container *ngIf="!hideButtons">
    <ng-container *ngIf="readonly; then readonlyButtons; else fullButtons"></ng-container>
  </ng-container>

  <ng-template #fullButtons>
    <ocf-form-button-row
      (cancel)="onCancel()"
      (save)="onSave()"
      [cancelText]="canCancel ? 'Cancel' : ''"
      [disableSubmit]="disableSubmit"
      [disabled]="isDisabled"
      [saveText]="canSave ? 'Save' : ''"
      [submitText]="submitText"
      cancelIcon="close"
      i18n-cancelText
      i18n-saveText
      i18n-submitText
      submitIcon="send"
    ></ocf-form-button-row>
  </ng-template>

  <ng-template #readonlyButtons>
    <ocf-form-readonly-button-row (closeClick)="formClosed.emit()" closeText="Ok" i18n-closeText></ocf-form-readonly-button-row>
  </ng-template>
</form>

<ng-container *ngIf="loading">
  <mat-spinner class="absolute-centered" mode="indeterminate" [diameter]="90"></mat-spinner>
</ng-container>
