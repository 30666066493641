import { markAllFormFieldsAsTouched } from '@1clickfactory/forms/helpers/formGroupHelpers';
import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'ocf-form',
    styleUrls: ['./ocf-form.component.css'],
    templateUrl: 'ocf-form.component.html',
    standalone: false
})
export class OcfFormComponent {
  @Input() canSave = true;
  @Input() canCancel = true;
  @Input() loading = false;
  @Input() submitText: string = '';
  @Input() hideDataNotice = false;
  @Input() addingContact = false;
  @Input() uploadingFiles = false;
  @Input() disableSubmit = false;
  /** Control paths that should stay disabled even if form is enabled */
  @Input() disabledControlPaths: string[] = [];
  @Input() hideButtons = false;

  @Output() formClosed = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  constructor(@Optional() private controlContainer: ControlContainer) {}

  private _readonly = false;

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = value || false;
    this.setFormDisabledState(this.isDisabled);
  }

  private _disabled = false;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value || false;
    this.setFormDisabledState(this.isDisabled);
  }

  get form(): UntypedFormGroup {
    return this.controlContainer && (this.controlContainer.control as UntypedFormGroup);
  }

  get showValidation(): boolean {
    return this.form && this.form.invalid && this.form.touched;
  }

  get isPending(): boolean {
    return (this.form && this.form.pending) || false;
  }

  get isDisabled(): boolean {
    return this.disabled || this.readonly || this.isPending;
  }

  onSave(): void {
    if (this.isDisabled) {
      return;
    }

    this.save.emit();
  }

  onSubmit(event: Event | any): void {
    // prevent HTML submit event from leaving component before validation happens
    event.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    if (this.form.invalid) {
      markAllFormFieldsAsTouched(this.form);
      return;
    }

    this.formSubmitted.emit();
  }

  onCancel(): void {
    if (this.disabled) {
      return;
    }

    this.cancel.emit();
  }

  private setFormDisabledState(isDisabled: boolean): void {
    if (!this.form) {
      return;
    }

    if (isDisabled && !this.form.disabled) {
      this.form.disable();
    } else if (!isDisabled && this.form.disabled) {
      this.form.enable();
      // there are cases where some controls must stay disabled (personal information email)
      if (this.disabledControlPaths && this.disabledControlPaths.length) {
        this.disabledControlPaths.forEach(p => {
          const control = this.form.get(p);
          if (control) {
            control.disable();
          }
        });
      }
    }
  }
}
