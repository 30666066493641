import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'ocf-contacts-messages',
    templateUrl: './ocf-contacts-messages.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OcfContactsMessagesComponent {
  @Input() showErrors: boolean = false;
  @Input() control!: AbstractControl;
}
