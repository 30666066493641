import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// noinspection ES6PreferShortImport
import { Contact } from '../../models/contacts.types';

@Component({
    selector: 'ocf-contacts-list',
    template: `
    <mat-chip-set>
      <mat-chip *ngFor="let contact of contacts; trackBy: trackBy" [removable]="!disabled" (removed)="contactRemoved.emit(contact)">
        {{ contact.Name }} ({{ contact.Email }})
        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OcfContactsListComponent {
  @Input() contacts: Contact[] = [];
  @Input() disabled: boolean = false;

  @Output() contactRemoved = new EventEmitter<Contact>();

  trackBy(index: number, contact: Contact): string {
    return contact.Email;
  }
}
