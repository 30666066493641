import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Contact } from './contacts.types'

export function mapFormGroupToContact(group: UntypedFormGroup): Contact {
  return group.value as Contact
}

export function mapContactToFormGroup(contact: Contact): UntypedFormGroup {
  return new UntypedFormGroup({
    Name: new UntypedFormControl(contact.Name, [Validators.required]),
    Email: new UntypedFormControl(contact.Email, [Validators.required, Validators.email]),
    Position: new UntypedFormControl(contact.Position),
    Phone: new UntypedFormControl(contact.Phone),
  })
}

export function getContactId(contact: Contact): string {
  return contact.Email
}
